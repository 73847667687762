@import 'src/assets/styles/custom/variables';

.layout {
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden;
  background-color: $bg2;
}

.auth-layout {
  height: 100%;

  &__wrap {
    display: flex;
    height: calc(100vh - 72px);
    background-color: $white;
    border-radius: 32px 32px 0 0;
  }

  &__sidebar {
    height: 100%;
    width: 300px;
    padding: 24px;
    border-right: 1px solid $greyLight;
  }

  &__content {
    height: 100%;
    width: calc(100% - 300px);
    overflow-y: auto;
  }
}

.nonauth-layout {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;

  &__logo {
    width: 256px;
    height: 80px;
    margin: 0 auto 72px;
    background: url('../../assets/images/svg/logo-colored.svg') 50% 50% no-repeat;
    background-size: 100% 100%;
  }
}

@media (max-width: $mobileBreakpoint) {
  .layout {
    &__row {
      padding-top: 12px;
      flex-wrap: wrap;
    }

    &__content {
      width: 100%;
    }

    &__sidebar {
      width: 100%;
    }
  }
}
