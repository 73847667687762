.scanner-modal {
    display: flex;
    min-width: 400px;
    min-height: 428px;
    
    padding: 32px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    &__label{
        margin-top: 1em;
        margin-bottom: 2em;
    }

    .qr {
        position: static;
        align-self: center;
        justify-self: center;
        margin-bottom: 18px;
    }
}