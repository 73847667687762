@import "src/assets/styles/custom/variables";

.table-actions {
  display: flex;
  margin-bottom: 8px;
  justify-content: flex-end;

  &__pagination {
    display: flex;
    align-items: center;
  }

  &__item {
    display: flex;
    align-items: center;

    &-name {
      margin-right: 8px;
      line-height: 20px;
      color: $greyDark2;
    }
  }

  &__page {
    display: flex;
    height: 36px;
    min-width: 96px;
    padding: 8px;
    margin-right: 16px;
    align-items: center;
    justify-content: space-between;
    //font-size: 14px;
    line-height: 20px;
    color: $mainDark;
    border-radius: 8px;
    background-color: $bg;

    &-total {
      color: $greyDark2;
    }
  }

  &__pag-btn {
    width: 36px;
    height: 36px;
    padding: 6px;
    border: none;
    background-color: transparent;
  }
}

.table {
  width: 100%;
  border-collapse: collapse;

  th {
    font-family: $altFont;
    line-height: 16px;
    color: $black;
    font-weight: 600;
    text-align: left;
  }

  td {
    padding: 26px 0;
    border-bottom: 1px solid $greyLight;
  }
}

.table-title {
  margin-bottom: 15px;
  font-weight: bold;
  text-decoration: underline;
}
