.vh-actions {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;

  &__export {
    margin-left: 100px;

    p {
      margin-bottom: 10px;
    }
  }

  .RangedatePicker {
    width: 100%;
    margin: 20px 0;
  }
}
