@import '../../../assets/styles/custom/variables';

.rs-window {
  max-width: 900px;

  &__imageCont {
    height: auto;
    text-align: left;
    margin-bottom: 25px;

    img {
      height: auto;
      max-height: 500px;
      object-fit: contain;
    }
  }

  &__imageZoomWrapper {
    width: 100%;
    display: flex;
    padding: 0px 50px;
  }

  .button {
    margin-right: 20px;
  }

  &__type,
  &__lft-score {
    margin-bottom: 20px;
  }

  &__input_row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 20px;

    .input {
      display: flex;
      flex-direction: row;

      input {
        width: 300px;
      }

      &__title {
        font-size: 16px;
        font-weight: bolder;
      }
    }
  }

  &__results {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 25px 0px;

    .result-title {
      margin-right: 15px;

      &__error {
        color: $danger;
      }

      &__default {
        color: $black;
      }
    }

    .result-toggle {
      height: 48px;
      padding: 0 20px;
      font-size: 16px;
      font-weight: 600;
      line-height: 46px;
      border-radius: 12px;
      cursor: pointer;
      background-color: white;
      margin: 0px 5px;
    }

    .selected-negative {
      background-color: $success;
      color: $white;
    }

    .selected-positive {
      background-color: $error;
      color: $white;
    }

    .selected-inconclusive {
      background-color: $warning;
      color: $white;
    }

    &__negative {
      border: 2px solid $success;
      color: $success;
    }

    &__positive {
      border: 2px solid $error;
      color: $error;
    }

    &__inconclusive {
      border: 2px solid $warning;
      color: $warning;
    }
  }

  &__actions-title {
    margin-bottom: 20px;
  }

  &__actions {
    display: flex;
    justify-content: center;
  }

  &__test-details {
    margin: 10px 0;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    .spacing {
      margin-right: 20px;
    }
  }
}

.fluid {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  font-family: Arial;
  line-height: 1.3;
  font-size: 16px;

  &__small-image {
    width: 50%;
  }
}

.fluid__instructions {
  flex: 0 0 auto;
  margin: 0 20px;
  padding: 20px 0 21px;

  &__preview {
    border: 1px solid #000;
    font-weight: normal;
    font-size: 16px;
    color: #ccc;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    h3 {
      position: absolute;
    }
  }
}

.fixed__instructions {
  flex: 1;
  margin: 0 20px;
}

@media (min-width: 480px) {
  .fluid {
    flex-direction: row;
  }

  .fluid__image-container {
    flex: 0 0 36%;
    margin: 20px 0 20px 0;
  }

  .fluid__instructions {
    flex: 0 0 60%;
    padding-top: 30px;
  }

  .fixed__instructions {
    padding-top: 30px;
    margin: 0 10px;
  }

  .portal {
    position: absolute;
    top: 40px;
    left: -30px;
  }
}
