@import "src/assets/styles/custom/variables";

.edit-card {
  display: flex;
  padding: 16px;
  border-radius: 12px;
  border: 1px solid $border;
  flex-wrap: wrap;

  &__left {
    width: 50%;
    padding-right: 16px;
    border-right: 1px dashed $greyDark;
  }

  &__right {
    width: 50%;
    padding-left: 16px;
  }

  &__footer {
    width: 100%;
    margin-top: 16px;
    padding-top: 16px;
    border-top: 1px dashed $greyDark;
  }

  .form-group {
    margin-bottom: 0;
  }

  .checkbox {
    width: 100%;
    margin-bottom: 16px;
  }
}