.broadcast-form {
  max-width: 800px;

  &__group {
    display: flex;
    width: 100%;
    align-items: flex-end;

    .select-menu, .input {
      margin-right: 60px;
    }

    .checkbox {
      width: max-content;
    }
  }
}