@import "src/assets/styles/custom/variables";

.sign-in {
  width: 360px;

  &__title {
    margin-bottom: 24px;
  }

  &__subtitle {
    margin-bottom: 16px;
  }

  &__forget {
    padding-top: 20px;
    font-size: 14px;

    a {
      color: $primary;
      font-weight: 700;
      text-decoration: none;
    }
  }

  &__setup {
    margin-top: 50px;
    padding-top: 20px;
    border-top: 1px solid $greyLight;

    .button {
      width: 100%;
      margin-top: 16px;
    }
  }
}
