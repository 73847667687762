@import "src/assets/styles/custom/variables";

.select-menu {
  &__title {
    line-height: 20px;
    margin-bottom: 8px;
    font-weight: 600;
  }

  &__menu {
    z-index: 100 !important;
  }

  &__control {
    height: 48px;
    border-radius: 12px !important;
    background: $bg !important;
    border: 1px solid $bg !important;
    box-shadow: none !important;
  }

  &__value-container {
    padding: 0 16px;
  }

  &__indicator-separator {
    display: none;
  }

  &__indicator {
    width: 36px !important;
    height: 36px !important;
  }
}

@media (max-width: $mobileBreakpoint) {
  .select-menu {
    &__control {
      height: 40px;
    }
  }
}
