.InputFromTo {
  // calendars
  .DayPicker-Months {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    @media screen and (min-width: 769px) {
      width: 690px;
      flex-direction: row;
    }
  }

  // input
  .DayPickerInput {
    display: inline-block;

    input {
      padding: 10px 10px;
      border: 1px solid #dadadc;
      font-family: 'Inter', sans-serif;
      font-size: 16px;
      line-height: 24px;
      color: #121219;
    }
  }
  // modifiers
  .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: #f0f8ff !important;
    color: #23762e;
  }

  .DayPicker-Day {
    border-radius: 0 !important;
  }

  .DayPicker-Day--start {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }
  .DayPicker-Day--end {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    background-color: #23762e;
    color: #f0f8ff;
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
    background-color: #23762e;
  }

  .DayPicker-Day--today {
    color: #121219;
    font-weight: 700;

    &:hover {
      opacity: 1;
      color: #121219;
    }
  }
  .date__picker__container {
    position: relative;
    display: inline-block;
  }
  .remove__btn {
    position: absolute;
    right: -2px;
    top: -2px;
    background-image: url('../../../assets/images/svg/icon-remove.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    height: 20px;
    width: 20px;
    cursor: pointer;
  }
}
