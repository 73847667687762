@import 'src/assets/styles/custom/variables';

.radio-group {
  &__title {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 8px;
    font-weight: 600;
  }

  &__options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 500px;

    .radio-item {
      display: block;
      position: relative;
      padding-left: 35px;
      margin-bottom: 12px;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;

        &:checked ~ .checkmark:after {
          display: block;
        }
      }

      .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        border: 1px solid $greyDark;
        border-radius: 50%;

        &:after {
          content: '';
          position: absolute;
          display: none;
        }
      }

      input:checked ~ .checkmark {
        background-color: $success;
        border: none;
      }

      .checkmark::after {
        top: 9px;
        left: 9px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: white;
      }
    }
  }
}
