$mainFont: 'Inter', sans-serif;
$altFont: 'Epilogue', sans-serif;

$white: #ffffff;
$black: #000000;
$primary: #23762e;
$primary-50: #cfe9d3;
$primary-500: #463da3;
$primaryLight: rgba(87, 205, 102, 0.1);
$mainDark: #121219;
$grey: #d4d4d5;
$greyLight: #dadadc;
$greyLight2: #b4b4bc;
$greyDark: #a4a4a7;
$greyDark2: #858589;
$bg: #f4f4f4;
$bg2: #f6f5fa;
$border: #c2c2c4;
$orange: #f26f46;
$purple: #3b00b3;
$danger: #ff0000;
$warning: #ea8915;
$success: #00b75f;
$error: #d80936;

$mobileBreakpoint: 676px;
