@import "src/assets/styles/custom/variables";

.page-header {
  display: flex;
  padding: 26px 24px;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  border-bottom: 1px solid $greyLight;

  &__title {
    line-height: 32px;
    font-weight: 700;
    color: $mainDark;
  }

  &__search {
    input {
      height: 40px;
      width: 264px;
    }
  }

  &__sections {
    display: flex;
    width: 100%;
    margin-bottom: -26px;
  }

  &__section-btn {
    padding: 11px 0;
    font-family: $altFont;
    font-size: 14px;
    color: $mainDark;
    margin-right: 24px;
    text-decoration: none;
    border-bottom: 2px solid transparent;

    &--active {
      font-weight: 600;
      border-color: $mainDark;
    }
  }
}
